<template>
  <div>
    <load-profile />
    <bet-setting-menu />

    <!-- Table Container Card -->
    <b-card>
      <validation-observer
        ref="editForm"
        #default="{ invalid }"
      >
        <!-- form -->
        <n-form-confirmation
          key="editForm"
          :form="$refs.editForm"
          :disabled="invalid"
          @submit="submit"
        >
          <n-input
            v-model="data"
            :fields="fields"
            :init-value="initData"
          >
            <template #lotterySettings="item">
              <lottery-setting-form
                v-model="data.lotterySettings"
                :field="item.field"
              />
            </template>
            <template #parentId="item">
              <b-form-group :label-for="item.field.key">
                <validation-provider
                  #default="{ errors }"
                  :vid="item.field.key"
                  :name="$t(item.field.label)"
                  :rules="item.field.rules"
                >
                  <label>
                    {{ $t(item.field.label) }}
                  </label>
                  <n-async-single-select
                    v-model="data[item.field.key]"
                    :name="item.field.key"
                    :repository="item.field.repository"
                    :selection-key="item.field.selectionKey"
                    :selection-label="item.field.selectionLabel"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                    :readonly="true"
                    @optionChange="changeOption"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </template>
            <template #loginTimeout="item">
              <b-form-group :label-for="item.field.key">
                <validation-provider
                  #default="{ errors }"
                  :vid="item.field.key"
                  :name="$t(item.field.label)"
                  :rules="item.field.rules"
                >
                  <label>
                    {{ $t(item.field.label) }}
                  </label>
                  <n-single-select
                    v-model="data[item.field.key]"
                    :reduce="true"
                    :options="timeout"
                    :clearable="false"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </template>
          </n-input>
          <b-row>
            <b-col
              cols="12"
              class="text-right"
            >
              <n-button-loading
                v-if="$can('update', 'player')"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mt-1 mr-1"
                :loading="loading"
              >
                {{ $t("button.saveChanges") }}
              </n-button-loading>
              <n-button-loading
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                :loading="loading"
                class="mt-1"
                @submit="back"
              >
                {{ $t("button.back") }}
              </n-button-loading>
            </b-col>
          </b-row>
        </n-form-confirmation>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */
import {
  BFormGroup,
  BRow,
  BCol,
  BCard,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import Repository from '@/repositories/RepositoryFactory'
import Ripple from 'vue-ripple-directive'
import NButtonLoading from '@/components/NButtonLoading.vue'
import NFormConfirmation from '@/components/NFormConfirmation.vue'
import NInput from '@/components/NInput.vue'
import Alphabet from '@/data/numberAlphabet'
import Timeout from '@/data/timeout'
import NAsyncSingleSelect from '@/components/NAsyncSingleSelect.vue'
import NSingleSelect from '@/components/NSingleSelect.vue'
import FormInput from './formInput'
import ExternalFormInput from './externalFormInput'
import BetSettingMenu from './BetSettingMenu.vue'
import LotterySettingForm from './LotterySettingForm.vue'

const PlayerRepository = Repository.get('player')
const LotteryRepository = Repository.get('lottery')

export default {
  components: {
    BFormGroup,
    BRow,
    BCol,
    BCard,
    NButtonLoading,
    NFormConfirmation,
    NInput,
    NAsyncSingleSelect,
    NSingleSelect,
    BetSettingMenu,
    LotterySettingForm,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {
        lastName: null,
        phone: null,
        username: null,
        password: null,
        ccy: null,
        lotteryIds: [],
        lotterySettings: [],
        isEnable: true,
        isReadonly: false,
        commissionNumTwo: 0,
        commissionNumThree: 0,
        maxWinDay: 0,
        maxTodayBet: 0,
        minBetInvoice: 0,
        maxBetInvoice: 0,
        minBetGame: 0,
        maxBetGame: 0,
        minBetPerNumTwo: 0,
        maxBetPerNumTwo: 0,
        minBetPerNumThree: 0,
        maxBetPerNumThree: 0,
      },
      initData: {},
      loading: false,
      checkUsernameLoading: false,
      usernameValid: false,
      getSuggestionUsernameLoading: false,
      username: {
        parent: null,
        first: null,
        second: null,
        third: null,
        fourth: null,
      },
    }
  },
  computed: {
    fields() {
      if (this.data.isVendor) {
        return [...ExternalFormInput]
      }

      const fields = FormInput.map(value => {
        if (value.key === 'password' || value.key === 'retypePassword') {
          value.rules = value.rules.replace('required|', '')
          value.rules = value.rules.replace('required', '')
        } else if (value.key === 'username') {
          value.disabled = true
        }

        return value
      })

      return fields
    },
  },
  mounted() {
    this.show()
  },
  methods: {
    getLottery() {
      LotteryRepository.listActive().then(response => {
        const data = response?.data?.data
        if (data) {
          data.forEach(element => {
            this.data.lotterySettings.push({
              lotteryName: element.name,
              lotteryNameKh: element.nameKh,
              lotteryId: element.id,
              commissionTypeThree: 0,
              commissionTypeTwo: 0,
              commissionNumTwo: null,
              commissionNumThree: null,
            })
          })
        }
      })
    },
    changeOption(value) {
      this.username.parent = value?.username
    },
    show() {
      PlayerRepository.show(this.$route.params.id).then(response => {
        const data = response?.data?.data
        if (data) {
          this.initData = {
            ...data,
          }

          if (!data.lotterySettings.length) {
            this.getLottery()
          }
        }
      })
    },
    submit() {
      this.$refs.editForm.validate().then(success => {
        if (success) {
          this.loading = true
          PlayerRepository.update(this.$route.params.id, this.data)
            .then(response => {
              this.$router.push({
                name: 'view-player',
                params: { id: response.data.data.id },
              })
            })
            .catch(error => {
              if (error.response?.status === 422) {
                this.$refs.editForm.setErrors(error.response?.data?.errors)
              }
            })
            .then(() => {
              this.loading = false
            })
        }
      })
    },
    back() {
      this.$router.back()
    },
  },
  setup() {
    const alphabet = [...Alphabet]
    const timeout = [...Timeout]
    return { alphabet, timeout }
  },
}
</script>
